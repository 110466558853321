:root {
    --background-color: #ffffff;
    --poweredbybg-color: #f0f0f0;
    --text-color: #000000;
    --link-color: #008DD5;
    --linkhover-color: #005A88;
    --main-font: 'Arvo'; }

.editing-active {
    transition: all 0.2s ease-in-out;
    border: 3px dotted rgba(0, 0, 0, 0.2); }
.editing-active:hover {
    border-color: rgba(31, 175, 132, 0.8);
    background-color: rgba(31, 175, 132, 0.3);
    cursor: pointer; }

.placeholder-text {
    font-style: italic;
    opacity: 0.6; }

* {
    font-family: var(--main-font);
    font-weight: 400;
    color: var(--text-color); }

body {
    padding: 0;
    margin: 0;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-font-feature-settings: "pnum";
    font-feature-settings: "pnum";
    font-variant-numeric: proportional-nums;
    position: relative;
    background: #fff; }

a {
    color: var(--link-color);
    transition: all 0.2s linear; }
a:focus, a:hover {
    color: var(--linkhover-color); }
a.nohover:hover {
    text-decoration: none; }

.claim-bar {
    padding: 12px 0;
    background: #fff;
    color: #555;
    font-family: 'Oxygen', Helvetica, sans-serif;
    transition: all 0.3s ease-in-out; }
.claim-bar * {
    font-family: 'Oxygen', Helvetica, sans-serif; }
.claim-bar div {
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    font-size: 0.9em;
    cursor: pointer;
    color: #555; }
.claim-bar div span {
    transition: all 0.3s ease-in-out;
    color: #008DD5; }
.claim-bar:hover {
    text-decoration: none;
    text-decoration: none;
    background: #008DD5;
    color: white; }
.claim-bar:hover div {
    color: white; }
.claim-bar:hover div span {
    color: white; }

.whitelabel-badge-mobile {
    background: white;
    padding-left: 0.75em;
    padding-right: 0.75em;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    border-radius: 4px;
    text-align: center;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: 0.2s all ease-in-out; }
.whitelabel-badge-mobile .wl-label {
    font-size: 0.8em;
    color: #777; }
.whitelabel-badge-mobile * {
    transition: 0.2s all ease-in-out; }
.whitelabel-badge-mobile .st0 {
    fill: #1FAF84; }
.whitelabel-badge-mobile:hover {
    background: #1FAF84; }
.whitelabel-badge-mobile:hover * {
    color: white; }
.whitelabel-badge-mobile:hover .st0 {
    fill: #ffffff; }
@media (min-width: 491px) {
    .whitelabel-badge-mobile {
        display: none; } }

.promo-badge {
    display: block;
    position: relative;
    background: white;
    padding-left: 0.5em;
    padding-right: 0.5em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    text-align: left;
    transition: 0.2s all ease-in-out;
    cursor: pointer; }
.promo-badge * {
    font-family: 'Oxygen', Helvetica, sans-serif;
    transition: 0.2s all ease-in-out;
    line-height: normal;
    text-align: left; }
.promo-badge .pb-loading-container {
    padding: 0.5em; }
.promo-badge .pb-loading-container .lds-ring {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px; }
.promo-badge .pb-loading-container .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 32px;
    height: 32px;
    margin: 4px;
    border: 4px solid #ddd;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #ddd transparent transparent transparent; }
.promo-badge .pb-loading-container .lds-ring div:nth-child(1) {
    animation-delay: -0.45s; }
.promo-badge .pb-loading-container .lds-ring div:nth-child(2) {
    animation-delay: -0.3s; }
.promo-badge .pb-loading-container .lds-ring div:nth-child(3) {
    animation-delay: -0.15s; }

@keyframes lds-ring {
    0% {
        transform: rotate(0deg); }
    100% {
        transform: rotate(360deg); } }
.promo-badge .pb-main-container {
    display: none;
    flex-direction: row; }
.promo-badge .pb-main-container .pb-text-container {
    flex: 1;
    padding-right: 0.5em;
    align-self: center; }
.promo-badge .pb-main-container .pb-text-container .pb-h1 span {
    color: #555;
    font-size: 1.2em;
    margin-right: 0.2em; }
.promo-badge .pb-main-container .pb-text-container .pb-h1 .pb-ios {
    width: 16px;
    height: 16px;
    fill: #ccc;
    margin-bottom: -1px;
    display: none; }
.promo-badge .pb-main-container .pb-text-container .pb-h1 .pb-android {
    width: 15px;
    height: 15px;
    fill: #ccc;
    stroke: #ccc;
    margin-bottom: -1px;
    display: none; }
.promo-badge .pb-main-container .pb-text-container .pb-h2 {
    color: #888;
    font-size: 0.9em;
    margin-top: -2px; }
.promo-badge .pb-main-container .pb-text-container .pb-by {
    font-size: 0.7em;
    text-transform: uppercase;
    text-align: left;
    margin-top: 4px; }
.promo-badge .pb-main-container .pb-text-container .pb-by a {
    color: #aaa; }
.promo-badge .pb-main-container .pb-text-container .pb-by a:hover {
    color: #25d5a1;
    text-decoration: none; }
.promo-badge .pb-main-container img {
    width: 58px;
    height: 58px;
    border-radius: 8px;
    margin-right: 0.75em;
    align-self: center;
    border: none;
    display: none; }
@media (min-width: 600px) {
    .promo-badge {
        position: fixed;
        z-index: 999999999;
        top: 10px;
        right: 10px;
        border-radius: 8px;
        -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); } }

footer {
    background: #222; }
footer .editing-active {
    border-color: rgba(255, 255, 255, 0.8); }
footer p {
    color: #777 !important; }
footer .social-icons {
    background: #222;
    width: auto;
    display: inline-block; }
footer .social-icons .social-icon {
    transition: opacity 0.2s ease-in-out;
    display: inline-block;
    width: 32px;
    margin-right: 12px;
    opacity: 0.5;
    padding-top: 30px; }
footer .social-icons .social-icon path, footer .social-icons .social-icon polygon {
    transition: all 0.2s linear;
    fill: white; }
footer .social-icons .social-icon:hover {
    opacity: 1; }
footer .social-icons .social-icon.si-muted {
    border: 2px dashed rgba(255, 255, 255, 0.3);
    border-radius: 50%; }
footer .social-icons .social-icon.si-muted path, footer .social-icons .social-icon.si-muted polygon {
    transition: all 0.2s linear;
    fill: rgba(255, 255, 255, 0.3); }
footer .social-icons.editing-active {
    margin-top: 30px;
    padding: 10px;
    margin-left: -10px; }
footer .social-icons.editing-active .social-icon {
    padding-top: 0; }
footer .footer-store-button-container {
    text-align: right;
    margin: auto;
    vertical-align: middle; }
@media (max-width: 767px) {
    footer .footer-store-button-container {
        text-align: center; } }
footer .footer-store-button-container .footer-store-button {
    padding-top: 2em;
    padding-bottom: 2em;
    display: inline-block; }
footer .footer-store-button-container .footer-store-button .store-button {
    height: auto !important;
    margin: 0 6px; }
footer .footer-store-button-container .footer-store-button .store-button img {
    height: 50px !important; }

#header .store-button img {
    height: 60px !important; }

#attribution {
    transition: all 0.2s ease-in-out;
    padding: 2em 0;
    font-size: 0.8em;
    color: #888 !important; }
#attribution p {
    margin-bottom: 0.45em;
    font-size: 1.1em; }
#attribution a {
    color: #aaa !important;
    text-decoration: none; }
#attribution a:hover {
    color: #007bff !important; }

h1 {
    font-size: 3.2em;
    margin-bottom: 0;
    font-weight: bold;
    letter-spacing: 0.05em; }

h2 {
    margin-top: 0.2em;
    margin-bottom: 0.3em;
    font-size: 1.8em; }

h3 {
    font-weight: normal;
    font-size: 16px; }

html {
    height: 100%; }

body {
    height: 100%;
    color: var(--text-color);
    text-align: center; }

.cover-container {
    margin-right: auto;
    margin-left: auto; }

.cover {
    padding: 0 1.5rem; }
.cover .btn-lg {
    padding: .75rem 1.25rem;
    font-weight: 700; }

p[id='text.line2'] {
    margin-bottom: 0 !important; }

.site-wrapper {
    position: relative;
    background-size: cover;
    min-height: 100vh; }
.site-wrapper .editing-active {
    border-color: var(--text-color); }
.site-wrapper .site-wrapper-background {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.9;
    background-color: var(--background-color); }
.site-wrapper .site-wrapper-inner {
    padding-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh; }
@media (min-width: 767px) {
    .site-wrapper .site-wrapper-inner {
        padding-top: 0; } }
.site-wrapper .site-wrapper-inner .arrows {
    width: 60px;
    height: 32px;
    position: absolute;
    left: 50%;
    margin-left: -30px;
    bottom: 15px;
    opacity: 0.5; }
@media (max-width: 786px) {
    .site-wrapper .site-wrapper-inner .arrows {
        display: none; } }
.site-wrapper .site-wrapper-inner .arrows path {
    stroke: var(--text-color);
    fill: transparent;
    stroke-width: 2px; }

@media (min-width: 40em) {
    .cover-container {
        width: 100%; } }

@media (max-width: 786px) {
    * {
        text-align: center; } }

#header {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: auto;
    align-items: center;
    justify-content: center; }
#header .text-container {
    text-align: left;
    position: relative;
    display: block;
    padding-left: 2em;
    padding-right: 2em;
    flex: 1;
    max-width: 800px; }
#header .text-container .tc-table {
    width: 100%;
    display: table;
    height: auto;
    padding-bottom: 5em;
    padding-top: 2em; }
@media (min-width: 787px) {
    #header .text-container .tc-table {
        height: 100%;
        padding-bottom: 0; } }
#header .text-container .tc-table .editing-ttp {
    padding: 1em;
    font-size: 1.2em; }
#header .text-container .tc-table .editing-app-button {
    font-style: italic;
    padding: 0.25em;
    font-size: 1.2em;
    display: inline-block;
    border-radius: 1em; }
#header .text-container .tc-table .editing-app-button:first-of-type {
    margin-right: 0.5em; }
#header .text-container h1 {
    font-size: 2em;
    margin-bottom: 0.25em;
    font-weight: bold;
    letter-spacing: 0.05em;
    line-height: 1.1em; }
@media (min-width: 787px) {
    #header .text-container h1 {
        font-size: 3.2em; } }
#header .text-container h2 {
    margin-top: 0.2em;
    margin-bottom: 0.3em;
    font-size: 1.3em; }
@media (min-width: 787px) {
    #header .text-container h2 {
        font-size: 1.8em; } }
#header .device-container {
    padding-bottom: 2em;
    width: 100%; }
#header .device-container img {
    max-height: 70vh;
    max-width: 90%;
    position: relative;
    margin-top: 5em; }
@media (min-width: 787px) {
    #header .device-container {
        width: auto;
        max-width: 40vw;
        padding-bottom: 0; }
    #header .device-container img {
        margin-top: 0; } }

.store-button {
    width: auto;
    display: inline-block; }
.store-button:first-of-type {
    margin-right: 12px; }
@media (max-width: 491px) {
    .store-button:first-of-type {
        margin-right: 0;
        margin-bottom: 12px; } }
.store-button:hover {
    transition: all 0.2s ease-in-out;
    transform: scale(1.05); }
.store-button svg {
    width: auto;
    height: 100%;
    display: block;
    position: relative; }
@media (max-width: 768px) {
    .store-button {
        margin: auto; } }

@media (max-width: 491px) {
    .text-to-phone:not(.email-subscribe) {
        display: none !important; } }

.text-to-phone input[type='tel'], .text-to-phone input[type='email'] {
    transition: all 0.2s linear;
    padding-top: 4px;
    padding-bottom: 4px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    outline: none;
    color: #333 !important;
    text-align: left; }
.text-to-phone input[type='tel']:focus, .text-to-phone input[type='email']:focus {
    border-color: var(--link-color); }

.text-to-phone input[type='email'] {
    padding-left: 12px;
    width: 230px; }

.text-to-phone button, .text-to-phone input[type='submit'] {
    border-radius: 4px;
    background: var(--link-color);
    color: white;
    font-size: 14px;
    border: 0;
    padding: 6px 8px;
    padding-top: 7px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    outline: none;
    cursor: pointer; }

section {
    padding: 5em 0;
    background: white; }
section:not(.text-section) * {
    color: #333; }
section h2 {
    font-size: 2.5em;
    color: #333;
    padding-bottom: 0.25em;
    margin-bottom: 0; }

#screenshots .owl-nav {
    padding-top: 3em; }
#screenshots .owl-nav button {
    outline: none; }
#screenshots .owl-nav .owl-next, #screenshots .owl-nav .owl-prev {
    font-size: 2em; }
#screenshots .owl-nav .owl-next *, #screenshots .owl-nav .owl-prev * {
    color: rgba(0, 0, 0, 0.25); }
#screenshots .owl-nav .owl-prev {
    margin-right: 1em; }

#screenshots img {
    border-radius: 0.5em;
    -webkit-box-shadow: 2px 2px 10px -2px rgba(0, 0, 0, 0.49);
    -moz-box-shadow: 2px 2px 10px -2px rgba(0, 0, 0, 0.49);
    box-shadow: 2px 2px 5px -2px rgba(0, 0, 0, 0.49); }

#screenshots h2 {
    padding-bottom: 0.5em;
    margin-bottom: 2em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: inline-block;
    padding-left: 2em;
    padding-right: 2em; }

@media (max-width: 767px) {
    #screenshots h2 {
        padding-left: 0;
        padding-right: 0;
        display: block; } }

#features p {
    font-size: 1.4em; }

#features ul {
    padding-left: 1em;
    padding-top: 0.75em; }
#features ul li {
    list-style-type: circle;
    font-size: 1.2em;
    padding-bottom: 0.5em; }

#features button {
    transition: transform 0.2s ease-in-out;
    padding: 0.75em 1.5em;
    border: 0;
    border-radius: 4px;
    margin-top: 1em;
    background-color: var(--background-color);
    color: white;
    cursor: pointer; }
#features button:hover {
    transform: scale(1.1); }

#features .feature-right {
    text-align: left;
    padding-top: 2em; }

#features .feature-left {
    text-align: center;
    padding-right: 0; }
#features .feature-left img {
    max-width: 100%;
    max-height: 575px; }

@media (min-width: 767px) {
    #features .feature-left {
        margin: auto;
        padding-right: 40px; } }

@media (max-width: 767px) {
    #features .feature-right {
        padding-top: 4em; }
    #features ul {
        padding-left: 0; }
    #features ul li {
        list-style: none; } }

#reviews .stars {
    width: 100%;
    padding-bottom: 1.5em; }
#reviews .stars svg {
    color: #eec643;
    height: 34px;
    width: 34px; }
#reviews .stars svg path {
    fill: #eec643; }

#reviews #quote-carousel {
    padding: 0 80px; }
#reviews #quote-carousel .carousel-control svg {
    width: 14px; }
#reviews #quote-carousel .carousel-control path {
    fill: #999; }
#reviews #quote-carousel .carousel-indicators {
    margin-bottom: -1em; }
#reviews #quote-carousel .carousel-indicators li {
    background: #c0c0c0; }
#reviews #quote-carousel .carousel-indicators .active {
    background: #666; }

#reviews blockquote {
    position: relative;
    font-size: 1em;
    font-style: italic;
    color: #666;
    padding: 0 5%;
    margin-bottom: 0; }
@media (min-width: 768px) {
    #reviews blockquote {
        font-size: 1.6em;
        line-height: 1.5em;
        padding: 0 10%; } }
#reviews blockquote footer {
    background: none !important;
    font-size: 0.6em;
    font-weight: 700;
    color: #c7c6c1; }
#reviews blockquote footer:before {
    content: '\2015'; }

a.contact {
    color: #999; }

a.contact:hover {
    color: #fff;
    text-decoration: none; }

@media (max-width: 767px) {
    * {
        text-align: center; }
    section.text-section .col-xs-12 {
        padding: 0 15px; } }

#dl-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: white;
    padding-top: 5em;
    display: none; }
#dl-loader .coffee_cup {
    width: 20px;
    height: 24px;
    border: 1px #999 solid;
    border-radius: 0px 0px 5px 5px;
    position: relative;
    margin: 12px auto; }
#dl-loader .coffee_cup:after, #dl-loader .coffee_cup:before {
    position: absolute;
    content: ""; }
#dl-loader .coffee_cup:after {
    width: 5px;
    height: 12px;
    border: 1px #999 solid;
    border-left: none;
    border-radius: 0px 20px 20px 0px;
    left: 20px; }
#dl-loader .coffee_cup:before {
    width: 1px;
    height: 6px;
    background-color: #999;
    top: -10px;
    left: 4px;
    box-shadow: 5px 0px 0px 0px #999, 5px -5px 0px 0px #999, 10px 0px 0px 0px #999;
    -webkit-animation: steam 1s linear infinite alternate;
    -moz-animation: steam 1s linear infinite alternate;
    animation: steam 1s linear infinite alternate; }

@-webkit-keyframes steam {
    0% {
        height: 0px; }
    100% {
        height: 6px; } }

@-moz-keyframes steam {
    0% {
        height: 0px; }
    100% {
        height: 6px; } }

@keyframes steam {
    0% {
        height: 0px; }
    100% {
        height: 6px; } }
#dl-loader div {
    color: #666;
    font-size: 0.9em; }


.global_style{
    --background-color: #004AC5;
    --text-color: #ffffff;
    --link-color: #EEC643;
    --linkhover-color: #005A88;
    --main-font: 'Oxygen';
    --backgroundgradient: True;
    --backgroundopacity: 0.9;
}